import React, { FC } from 'react';

import Typography from 'common/Typography';

import { ContactUsProps } from './models.d';

import './ContactUs.scss';

const ContactUs: FC<ContactUsProps> = ({ contactUsText }) => (
  <div id="contact-us" className="contact-us">
    <Typography dangerouslySetInnerHTML={contactUsText} padding={{ top: 'md' }} size={20} />
  </div>
);

export default ContactUs;
