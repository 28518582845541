import React, { FC } from 'react';

import Typography from 'common/Typography';
import Accordion, { AccordionVariant } from 'components/Accordion';

import { FaqProps } from './models.d';

import './Faq.scss';

const { PRIMARY } = AccordionVariant;

const Faq: FC<FaqProps> = ({ accordionItems, faqTitle }) => {
  const renderFaq = (): JSX.Element[] | null => {
    if (!accordionItems) return null;

    return accordionItems.map(({ title, description }): JSX.Element => {
      const visibleContent = (
        <Typography size={24} weight="bold">
          {title}
        </Typography>
      );

      const hiddenContent = (
        <Typography dangerouslySetInnerHTML={description} padding={{ top: 'md' }} size={14} />
      );

      return (
        <Accordion
          key={title}
          className="faq__accordion"
          variant={PRIMARY}
          iconSize="small"
          visibleContent={visibleContent}
          hiddenContent={hiddenContent}
        />
      );
    });
  };

  return (
    <div className="faq" data-testid="faq-item" id="faq">
      <Typography
        padding={{ top: 'xl', bottom: { base: 'xl', md: 'xxl' } }}
        className="faq__title"
        align="center"
        size={{ base: 30, md: 35 }}
        as="h2"
      >
        {faqTitle}
      </Typography>
      {renderFaq()}
    </div>
  );
};

export default Faq;
