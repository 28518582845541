import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner, {
  BannerBackgroundColor,
  BannerContentColor,
  BannerVariant,
} from 'components/Banner';
import ContactUs from 'components/ContactUs';
import Faq from 'containers/Faq';

const { SIMPLE_SECONDARY } = BannerVariant;
const { GREEN } = BannerBackgroundColor;
const { WHITE } = BannerContentColor;

const HelpPage: FC<HelpPageTypes.HelpProps> = ({
  data: {
    help: { urls, seo, banner, accordionItems, contactUsText, title: faqTitle },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner variant={SIMPLE_SECONDARY} backgroundColor={GREEN} contentColor={WHITE} {...banner} />
      <Container fluid>
        <Container gutter={false}>
          <Faq {...{ accordionItems, faqTitle }} />
          <ContactUs {...{ contactUsText }} />
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    help(lang: { eq: $lang }) {
      ...HelpPageFragment
    }
  }
`;

export default HelpPage;
